import React from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default class FadeInTitle extends React.Component {

  // ----- Props (* denotes required) -----
  // text* : String
  // duration : Int
  // delay : Int
  // className : String
  // onScroll : Boolean

  constructor(props) {
    super(props);
    this.state = {
    };

    this.titleRef = null;

    // If duration is undefined, set this.duration to 1.
    // Otherwise, set it to the given duration.
    this.props.duration === undefined ? this.duration = 1 : this.duration = this.props.duration;

    // If delay is undefined, set this.delay to 0.
    // Otherwise, set it to the given delay.
    this.props.delay === undefined ? this.delay = 0 : this.delay = this.props.delay;

    this.onScroll = !(this.props.onScroll === undefined || this.props.onScroll === false);

    gsap.registerPlugin(ScrollTrigger);

  }

  componentDidMount() {
    gsap.from(this.titleRef, this.duration,
      {
        scrollTrigger: this.onScroll ? this.titleRef : null,
        opacity: 0,
        delay: this.delay,
      });
  }


  render() {
    return (
      <div ref={div => this.titleRef = div} className={this.props.className}>
        {this.props.text}
      </div>
    )
  }
}
