import React from "react"
import Layout from "../components/layout"
import FadeInTitle from "../components/fade-in-title"
import "../stylesheets/pages/services.css"
import Img from "../../public/code.png"
import gsap from "gsap"
import Footer from "../components/footer.js"

export default function Services() {
  return (
    <Layout>

    <div class="whole">
      <div class="header mob"><strong><FadeInTitle text="Define." duration={0.7} delay={0}/>&nbsp;<FadeInTitle text="Design." duration={0.7} delay={0.5}/>&nbsp;<FadeInTitle text="Develop." duration={0.7} delay={1}/></strong></div>
      <div class="body mob">
        <p>First we help you define your MVP's core features.</p>
        <p>Then working closely with you we iteratively create a mock-up of your application.</p>
        <p>Finally we code your application keeping you in the loop  throughout the process.</p>
      </div>

      <div class="row">
        <div class="column mob">
          <div class="titleServices mob">
            <div>Specializing in:</div>
          </div>
          <div class="info">
            <div className="bullet mob">- Mobile Applications (iOS & Android)</div>
            <div className="bullet mob">- Web Applications</div>
            <div className="bullet mob">- Cloud Services</div>
            <div className="bullet mob">- Serverless Applications</div>
            <div className="bullet mob">- Application Data Analytics</div>
            <div className="bullet mob">- Mock-Ups</div>
          </div>
        </div>
        <div class="column mob">
          <div class="titleServices">
            <div>Tech we primarily use:</div>
          </div>
          <div class="info">
            <div className="bullet mob">- React</div>
            <div className="bullet mob">- React Native</div>
            <div className="bullet mob">- AWS Amplify, S3, DynamoDB, AppSync, Lambda Functions, API Gateway</div>
            <div className="bullet mob">- Adobe XD (Mock-Ups)</div>
          </div>
        </div>
        <div class="column mob">
          <div class="image mob"><img id="codeImg" src={Img} alt="pic" /></div>
        </div>
      </div>
    </div>
    <Footer color="white"/>
    </Layout>
  )
}
